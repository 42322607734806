<template>
  <div class="flex antialiased bg-f-bg bo:bg-f-white">
    <div class="flex-1 overflow-auto scroll-touch">
      <div class="flex flex-col w-full min-h-screen pt-6 md:pt-8 md:pb-32 relative">
        <div class="w-full flex items-center pl-4 md:pl-0 md:justify-center" :class="{ hidden: hide }">
          <logo />
        </div>
        <alert :flash="alertError" :dismissible="false" class="w-full mt-4 mx-4" />
        <slot />
        <GdprBanner @show-banner="showBanner" />
        <div
          class="fixed w-full h-full top-0 left-0 pointer-events-none select-none"
          :class="{ 'backdrop-blur bg-f-bg/20 hj:bg-transparent z-60': showOverlay || showGdprBanner }"></div>
      </div>
    </div>
    <div class="absolute top-0 right-0" :class="{ hidden: hide }">
      <HeaderMenu />
    </div>
  </div>
</template>

<script>
import ThemeToggle from "../components/ThemeToggle.vue"
import Logo from "../components/Logo.vue"
import HeaderMenu from "../components/HeaderMenu.vue"
import Alert from "../components/Alert.vue"
import GdprBanner from "../components/GdprBanner.vue"

export default {
  name: "Simple",
  components: {
    GdprBanner,
    Alert,
    HeaderMenu,
    ThemeToggle,
    Logo,
  },
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
    showOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentKey: 0,
      showGdprBanner: false,
    }
  },
  computed: {
    alertError() {
      const keys = Object.keys(this.$page.props.errors)
      if (keys.length > 0 && keys[0] === "error") {
        return {
          message: this.$page.props.errors?.["error"],
          type: "error",
        }
        return null
      }
    },
  },
  methods: {
    showBanner(event) {
      this.showGdprBanner = event
    },
  },
  updated() {
    this.componentKey++
  },
  created() {
    this.identifyWithCustomerIoWithCioId()
  },
}
</script>
